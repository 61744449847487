.modal-overlay .iro-checkbox .motif-checkbox-checked .motif-checkbox-inner,
.modal-overlay .iro-library-checkbox .motif-checkbox-checked .motif-checkbox-inner {
  background-color: #000000 !important;
  border-color: #000000 !important;
}

.modal-overlay .iro-checkbox .motif-checkbox-checked .motif-icon,
.modal-overlay .iro-library-checkbox .motif-checkbox-checked .motif-icon {
  color: #ffffff !important;
  fill: #ffffff !important;
}

.modal-overlay .iro-checkbox .motif-checkbox-checked .motif-checkbox-inner:hover,
.modal-overlay .iro-library-checkbox .motif-checkbox-checked .motif-checkbox-inner:hover {
  background: #000000 !important;
  border-color: #000000 !important;
}

.modal-overlay .iro-checkbox .motif-checkbox-checked:focus,
.modal-overlay .iro-library-checkbox .motif-checkbox-checked:focus {
  outline: 2px solid #ffffff !important;
}

.iro-checkbox {
  display: flex;
  align-items: center;
  justify-content: right;
  margin: auto;
}

.search-filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Ensure proper spacing between elements */
  margin-bottom: 20px;
  flex-wrap: wrap;
  /* Allow elements to wrap on smaller screens */
}

.search-container {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 2px 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  /* Add margin for better spacing */
  min-width: 150px;
  /* Set a minimum width for better responsiveness */
}

.filter-container {
  display: flex;
  align-items: center;
  width: 140px;
  margin-left: 10px;
}

.filter-container .motif-label {
  margin-right: 8px;
}

.search-box-input-iro {
  margin-left: 1rem;
  flex: 1;
  height: 32px;
}

.filter-container .filter-by-select {
  width: 100%;
}

.sidebar-item.disabled {
  pointer-events: none;
  opacity: 0.5;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .sustain-modal {
    width: 90%;
    margin: auto;
    padding: 20px;
    /* Add padding for better spacing */
  }

  .modal-body {
    display: flex;
    flex-direction: column;
  }

  .modal-sidebar {
    width: 100%;
    order: 2;
    margin-top: 20px;
    /* Add margin for better spacing */
  }

  .modal-content {
    width: 100%;
    order: 1;
  }

  .search-filter-container {
    flex-direction: column;
  }

  .search-container {
    width: 100%;
    margin-bottom: 10px;
  }

  .filter-container {
    width: 100%;
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .modal-header h2 {
    font-size: 1.2rem;
  }

  .modal-footer {
    flex-direction: column;
    align-items: center;
  }

  .modal-footer .button {
    width: 100%;
    margin-bottom: 10px;
  }

  .filter-container .motif-label {
    display: none;
  }

  .filter-container .filter-by-select {
    width: 100%;
  }
}

.iro-select {
  font-weight: 400;
}

.iro-sm-container {
  max-height: 350px;
  overflow-y: auto;
}

.iro-library-modal.modal {
  max-width: 90% !important;
  width: 90% !important;
}
